body, html {
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #0a131e;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

/* vietnamese */
@font-face {
  font-family: 'Encode Sans Semi Condensed';
  font-style: normal;
  font-weight: 400;
  src: local('Encode Sans Semi Condensed Regular'), local('EncodeSansSemiCondensed-Regular'), url(https://fonts.gstatic.com/s/encodesanssemicondensed/v3/3qT4oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1yZ9MRtRdjodUA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Encode Sans Semi Condensed';
  font-style: normal;
  font-weight: 400;
  src: local('Encode Sans Semi Condensed Regular'), local('EncodeSansSemiCondensed-Regular'), url(https://fonts.gstatic.com/s/encodesanssemicondensed/v3/3qT4oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1yY9MRtRdjodUA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Encode Sans Semi Condensed';
  font-style: normal;
  font-weight: 400;
  src: local('Encode Sans Semi Condensed Regular'), local('EncodeSansSemiCondensed-Regular'), url(https://fonts.gstatic.com/s/encodesanssemicondensed/v3/3qT4oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1yW9MRtRdjo.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

#root {
  height: 100%;
  width: 100%;
}

#root .pro-boot {
  height: 100%;
  width: 100%;
}

.theme-container {
  display: flex;
  flex-direction: column;
}

.text-label {
  display: block;
}

.mdc-list-item__primary-text {
  text-align: left;
}
