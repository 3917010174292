.employer-work-order-card {
  background-color: white !important;
  width: 100%;
  cursor: pointer;
  border-radius: 0 !important;
}

.employer-work-order-card > .mdc-layout-grid {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.ongoing-color {
  /* Button color green */
  border-left-color: #6ABB6A;
}

.upcoming-color {
  /* Button color orange */
  border-left-color: #FD6900;
}

.archived-color {
  border-left-color: transparent;
}

.work-order-card-border {
  border-radius: 0 !important;
  border-left-width: 5px;
  border-left-style: solid;
}

.invitations-neutral, .invitations-ok, .invitations-warning {
  padding: 2px 5px;
  color: black;
}

.invitations-ok {
  background-color: #6ABB6A;
  color: white;
  font-weight: 700;
}

.invitations-warning {
  /* background-color: #FD6900; */
  background-color: #e29b20;
  color: white;
  font-weight: 700;
}

.invitations-neutral > svg, .invitations-ok > svg, .invitations-warning > svg {
  margin-right: 5px;
}

.invitations-ok > svg, .invitations-warning > svg {
  color: white !important;
}

.work-order-billing-id {
  font-size: 12px;
  font-style: italic;
  opacity: 0.6;
}

.work-order-date-range {
  text-transform: capitalize;
}
