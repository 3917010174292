.employee-dialog .MuiPaper-root {
  height: 100%;
  max-height: 900px !important;
  width: 100%;
  max-width: 800px !important;
  margin: 0;
}

.employee-dialog .MuiDialogContent-root {
  padding-left: 0;
  padding-right: 0;
}

.employee-profile-tabs .mdc-tab--active .mdc-tab__icon,
.employee-profile-tabs .mdc-tab-indicator .mdc-tab-indicator__content--underline,
.employee-profile-tabs .mdc-tab__text-label,
.employee-profile-tabs .mdc-tab__icon {
  color: black !important;
  border-color: black !important
}

/* .employee-profile-tabs .mdc-tab__ripple:hover::before,
.employee-profile-tabs .mdc-tab__ripple:hover::after,
.employer-profile-tabs .mdc-ripple-upgraded--background-focused::before,
.employer-profile-tabs .mdc-ripple-upgraded--background-focused::after,
.employer-profile-tabs .mdc-ripple-upgraded--foreground-activation::before,
.employer-profile-tabs .mdc-ripple-upgraded--foreground-activation::after {
  background-color: gray;
  opacity: 0.2;
} */

.mdc-tab__ripple.mdc-ripple-upgraded::before {
  background-color: gray !important;
}

.employee-profile-tabs .mdc-tab__ripple:hover::before,
.employee-profile-tabs .mdc-tab__ripple:hover::after,
.employer-profile-tabs .mdc-tab__ripple::before,
.employer-profile-tabs .mdc-tab__ripple::after {
  background-color: gray !important;
  opacity: 0.2;
}

.employee-profile-tabs .mdc-tab--active {
  background-color: rgba(0, 0, 0, 0.2);
}

.employee-profile .pk-select.mdc-select:not(.mdc-select--disabled) select {
  color: black !important;
  background-color: white;
}

.employee-profile .pk-select.mdc-select:not(.mdc-select--disabled) label {
  color: rgba(0, 0, 0, 0.6) !important;
}

.employee-profile .mdc-text-field {
  caret-color: black;
}

.employee-profile .mdc-text-field label {
  color: rgba(0, 0, 0, 0.6) !important;
}

.employee-profile .mdc-text-field .mdc-line-ripple {
  background-color: black;
}

div.no-border-bottom {
  border-bottom: none !important;
}

/* div.no-border-bottom select, */
div.no-border-bottom input.mdc-text-field__input,
div.no-border-bottom select.mdc-select__native-control {
  border-bottom: none !important;
}

.custom-validities-employer .collapsible-card-handle.mdc-card {
  background-color: whitesmoke;
  border-radius: 0;
}

.custom-validities-employer .collapsible-card-handle.mdc-card .collapsible-card-handle-title {
  color: black;
}

.custom-validities-employer .collapsible-card-handle.mdc-card .mdc-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.6) !important;
}

.employer-checkbox > span.MuiIconButton-label {
  color: black;
}

.employer-checkbox.Mui-disabled > span.MuiIconButton-label {
  color: rgba(0, 0, 0, 0.38);
}

span.employer-checkbox.MuiButtonBase-root:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.employer-checkbox > span.MuiTouchRipple-root {
  display: none;
}

.disabled-profile-input-select select:disabled {
  border-bottom: 1px solid !important;
  border-color: black !important;
  background-color: #FEFEFE !important;
  color: black !important;
}

.disabled-profile-input input:disabled {
  background-color: #FEFEFE !important;
  color: black;
}

.disabled-profile-input-select input:disabled {
  border-bottom: 1px solid !important;
  border-color: black !important;
  background-color: #FEFEFE !important;
  color: black !important;
}


/* select + label.mdc-floating-label {
  top: auto !important;
} */

.employer-read-only-employee-dialog .disabled-profile-input {
  border-bottom: none !important;
  background-color: white !important;
}

/* We need this to correctly position the helper/error text below the text field */
.employee-dialog .required-text-field-wrapper {
  display: flex;
  flex-direction: column;
}

div.mdc-list-item .mdc-text-field-helper-line {
  width: 100%;
}

div.mdc-list-item .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: red !important;
}

.employee-dialog .mdc-card .headline {
  border-bottom: none;
  margin-left: 16px;
}

.employee-dialog .mdc-card .headline * {
  font-size: 16px !important;
  font-weight: normal;
}
