:focus {
  outline: none;
}

body {
  background-color: #1E232F;
  overscroll-behavior-y: contain;
  overflow-y: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  perspective: 1000;
  -webkit-perspective: 1000;
}

.app-content {
  align-items: center;
  background-color: #1E232F;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  /* height: calc(100vh - 100px); */
  justify-content: flex-start;
  overflow: auto;
  padding: 0;
}

.app-content-margin-top-default {
  margin-top: 100px;
}

.app-content-margin-top-medium {
  margin-top: 88px;
}

/* For when the navigation is hidden, e.g. during registration */
/* Leaves a small gap between the 40px height topmost bar (where the icon is) */
.app-content-margin-top-small {
  margin-top: 50px;
}

/* App content is pressed against the 40px height topmost bar (where the icon is), no gap */
.app-content-margin-top-smallest {
  margin-top: 40px;
}

.app-content-margin-top-none {
  margin-top: 0px;
}

/* .app-content-employer-small-margin-top {
  margin-top: 40px !important;
} */

.app-content-white {
  background-color: #F6F6F6;
  /* We need to reduce the oversized margin to hide the dark-colored body completely */
  /* margin-top: 88px; */
  color: black;
  /* Need to limit the width to make it look better on larger screens, like .top-bar */
  width: 800px;
  /* align-self: center; */
}

/* General use left padding for e.g. text elements that otherwise touch the left side of screen */
.padding-left {
  padding-left: 9px;
}

span.chatroom.unread {
  left: 21px;
  right: unset;
  top: 12px;
}

span.unread {
  background-color: #FF6A00;
  color: white;
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
  max-height: 13px;
  max-width: 17px;
  min-height: 13px;
  min-width: 17px;
  position: absolute;
  right: 21px;
  text-align: right;
  top: 10px;
}

.mdc-card .unread .rmwc-icon {
  color: #FF6A00 !important;
}

.mdc-card .unread .rmwc-icon,
.mdc-tab.unread .rmwc-icon {
  color: #FF6A00 !important;
}

.mdc-tab:not(.mdc-tab--active) .mdc-tab__icon,
.mdc-tab:not(.mdc-tab--active) .mdc-tab__text-label {
  color: white;
}

.mdc-tab--stacked {
  height: 60px;
}
.mdc-tab--stacked .mdc-tab__icon {
  padding-top: 6px;
}
.mdc-tab--stacked .mdc-tab__text-label {
  padding-bottom: 10px;
}

.wide-tab .mdc-tab__content {
  width: 100%;
}

.app-content-center {
  justify-content: center;
}

.top-bar {
  flex: 0 1 auto;
  width: 800px;
}

.side-menu {
  background-color: var(--mdc-theme-surface);
}

.side-menu * {
  color: white !important;
}

.side-menu .rmwc-icon {
  background: gray;
}

.side-menu .rmwc-avatar__text-inner {
  color: #404040 !important
}

.side-menu-header {
  min-height: 80px;
  padding-top: 10px;
  padding-bottom: 20px;
  hyphens: auto;
}

.side-menu-content {
  border-top: 1px solid gray;
}

.side-menu-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.side-menu-list .mdc-list-item {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  flex-shrink: 0;
}

.side-menu-list .bottom-item {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: auto;
}

.side-menu-list .primary-item {
  color: var(--mdc-theme-primary) !important;
}

.work-hour-card-buttons {
  width: 100%;
  justify-content: space-between;
  padding: 2px;
}

[class*="MUIDataTableFilter-gridListTile"] {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

@media (max-width: 599px) {
  .mdc-top-app-bar__row {
    height: 40px;
  }
}

@media (max-width: 800px) {
  .top-bar, .app-content-white {
    width: 100%;
  }
  .add-hourly-row-btn {
    margin: 0px !important;
    position: fixed;
    bottom: 15px;
    right: 15px;
  }
}

@media (max-width: 345px) {
  .add-hourly-row-btn {
    font-size: 12px;
  }
  .add-hourly-row-button-2 {
    font-size: 12px;
  }
}

.mdc-top-app-bar__row {
  /* box-shadow: 0 1px 1px #060B12; */
  height: 40px;
}
/* Disable padding so tabs fit the screen */
.mdc-tab {
  padding: 0 !important;
}

.mdc-text-field {
  margin: 0.5em 0;
}

@media all and (max-width: 1024px) {
  .logo {
    width: 80%;
  }
}

.logo {
  width: 15em;
  height: auto;
}

.mdc-card {
  margin: 0.35em 0;
}

.mdc-top-app-bar {
  background-color: #0D121C !important;
}

.App-header {
  background-color: #0a131e;
  color: white;
  font-size: calc(10px + 2vmin);
  min-height: 100vh;
}

.work-order__list {
  width: 100%;
}

.profile__details {
  width: 100%;
}

.rc-steps {
  max-width: 600px;
  width: 100%;
  min-height: 65px;
}

/* DayPicker styles */
.DayPicker {
  background: #1E2330;
}

.forced-white-text {
  color: white !important;
}

/* DayPicker range styling (e.g. resource-availability) start */
.range-daypicker .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
  background-color: #ecebde !important;
  color: #4a90e2;
}
.range-daypicker  .DayPicker-Day {
  border-radius: 0 !important;
}
.range-daypicker  .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.range-daypicker .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.range-daypicker .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
.range-daypicker .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
  border-radius: 0 !important;
  color: black !important;
}

.range-daypicker .DayPicker-Day--selected:not(.DayPicker-Day--disabled) {
    position: relative;
    background-color: var(--mdc-theme-primary) !important;
    color: black !important;
}

/* We want to separate the arrows and put the caption (month and year by default) in the middle */
/* This is because by default the arrows are too close together to be clicked on mobile */
.employer-daypicker .DayPicker-Caption {
  text-align: center;
}

.employer-daypicker .DayPicker-NavButton--prev {
  margin-right: 15.1em;
}

/* .SelectedWeekExample .DayPicker-Day--hoverRange:hover {
  border-radius: 0 !important;
} */
/* DayPicker range styling (e.g. resource-availability) end */

.employer-daypicker {
  color: black;
  background-color: white;
}

.work-period-dialog .theme-container > div {
  width: 100%;
}

.employer-daypicker .DayPicker-Day--selected {
  background-color: var(--mdc-theme-primary) !important;
  color: black !important;
}

.chat {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
}

.chat__container {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
}

.chat .chat__bottom_ref {
  margin-top: auto;
}

.chat__message  {
  display: flex;
  justify-content: flex-start;
  padding: 0 0.3em 0 0.3em;
  width: 100%;
}

.message__content {
  -webkit-box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  background-color: #454d62;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  flex: 0 1 auto;
  font-size: 0.85rem;
  height: auto;
  margin: 0.5em 3.5em 0.5em 0.5em;
  padding: 0.5em;
  vertical-align: baseline;
}

.message__content > * {
  float: none;
  margin: 0;
}

.message__avatar {
  align-self: flex-end;
  background-color: yellow;
  border-radius: 50%;
  flex: 0 1 auto;
  height: 30px;
  margin: 0.5em 0em 0.5em 0.5em;
  min-width: 30px;
  width: 30px;
}

.chat__message--mine {
  flex-direction: row-reverse;
}

.chat__message--mine > .message__avatar {
  display: none;
}

.chat__message--mine > .message__content {
  background-color: #726dff;
  margin: 0.5em 0.5em 0.5em 3.5em;
}

.chat__input .mdc-text-field--textarea .mdc-text-field__input {
  color: white !important;
  height: var(--chat-entry-height);
  max-height: 33vh;
  overflow: auto;
  /* padding-top: 8px; */
  resize: none !important;
}

.rc-steps.registration .rc-steps-item-title {
  color: var(--mdc-theme-on-primary);
}
/* .rc-steps.registration .rc-steps-item-wait .rc-steps-icon {
  color: var(--mdc-theme-on-secondary);
} */

.rc-steps-item-process .rc-steps-item-icon {
  background: var(--mdc-theme-accent-orange);
  border-color: var(--mdc-theme-accent-orange);
}
.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon,
.rc-steps.registration .rc-steps-item-wait .rc-steps-icon,
.rc-steps-item-icon > .rc-steps-icon {
  color: var(--mdc-theme-accent-orange);
}
.rc-steps-item-finish .rc-steps-item-tail:after {
  background-color: var(--mdc-theme-accent-orange);
}
.rc-steps-item-finish .rc-steps-item-icon {
  border-color: var(--mdc-theme-accent-orange);
}
.rc-steps-item-icon > .rc-steps-icon {
  font-weight: 800;
}

.registration.mdc-text-field--invalid input {
  border-bottom-width: 5px !important;
}

.mdc-text-field--disabled .mdc-floating-label,
.theme-dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #FEFEFE !important;
}

.disabled-input-ios-safari.mdc-text-field--disabled {
  background-color: #2F3440 !important;
}

.disabled-input-ios-safari.mdc-text-field--disabled > input.mdc-text-field__input {
  opacity: 1 !important;
}

/* .pk-select.mdc-select:not(.mdc-select--disabled) .mdc-floating-label {
  left: 0.3em;
  top: 0.9em;
} */

:local(.textwide) {
  max-width: 400px;
  width: 100%,
}

.ml-1r {
  margin-left: 1rem;
}
.ml-1 {
  margin-left: 1em;
}
.ml-2 {
  margin-left: 2em;
}

.inputfile {
	/* height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	width: 0.1px;
	z-index: -1; */
  display: none;
}
.cursor-pointer,
.inputfile + span {
  cursor: pointer;
}

.collapsible-card-handle {
  font-size: 18px;
  padding: 10px 15px !important;
  min-height: 40px;
  height: fit-content;
}

.collapsible-card-handle i {
  color: #C7C9CC;
}

.collapsible-card-handle i:first-of-type {
  /* A hack imitating <Icon>'s sizing option but with SimpleListItem's "graphic" prop */
  font-size: 30px;
  width: 1em;
  height: 1em;
}

.searchable-dropdown-handle {
  /* Match the input padding */
  padding: 14px 16px 0 16px !important;
  font-size: 16px;
}

.searchable-dropdown .mdc-text-field__input {
  color: white !important;
}

.searchable-dropdown input::-webkit-input-placeholder {
  color: gray !important;
}

.searchable-dropdown-found-item {
  font-size: 16px;
}

.move-next-errormsg + div.mdc-text-field-helper-line {
  left: 3em;
  position: absolute;
  top: 0em;
}

/* clears the 'X' from Internet Explorer */
input[type=time]::-ms-clear {
  display: none;
  height: 0;
  width : 0;
}
input[type=time]::-ms-reveal {
  display: none;
  height: 0;
  width : 0;
}

/* clears the 'X' from Chrome */
input[type="time"]::-webkit-search-decoration,
input[type="time"]::-webkit-search-cancel-button,
input[type="time"]::-webkit-search-results-button,
input[type="time"]::-webkit-search-results-decoration,
input[type="time"]::-webkit-calendar-picker-indicator,
input[type="month"]::-webkit-search-decoration,
input[type="month"]::-webkit-search-cancel-button,
input[type="month"]::-webkit-search-results-button,
input[type="month"]::-webkit-search-results-decoration {
  display: none;
}

input[type="time"]::-webkit-clear-button,
input[type="month"]::-webkit-clear-button {
  display: none;
}

input[type=time]::-webkit-inner-spin-button,mdc-text-field__input
input[type=time]::-webkit-outer-spin-button,
input[type=month]::-webkit-inner-spin-button,
input[type=month]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"],
input[type="time"] {
  -moz-appearance: textfield;
}

/* Gets rid of the empty space in time inputs that messes up the layout on mobile */
input[type="time"]::-webkit-date-and-time-value {
  margin: 0;
}

.pk-time-field.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
  padding: 0;
  text-align: center;
}

.pk-number-field.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
  padding: 0 0.5rem;
  text-align: center;
}

/* .rmwc-data-table__head-cell.pk-multiline-header {
  height: 1rem;
  line-height: 1rem;
  white-space: pre;
} */

.hourly-timelog-row td {
  border-top: none;
  border-bottom: none;
  box-shadow: 0;
  position: initial;
  border-bottom-color: var(--mdc-theme-surface);
}

.hourly-timelog-row td input {
  border: none;
}

.hourly-timelog-row .rmwc-data-table__cell {
  height: unset !important;
}

.hourly-timelog-row-first {
  margin-top: 15px;
}

.hourly-description {
  margin: 0;
  padding: 0;
}

.hourly-description textarea {
  margin: 0 !important;
  padding: 5px !important;
  min-height: 48px;
}

.hourly-timelog-dialog2 .mdc-dialog__container {
  height: 100%;
  width: 100%;
}

.hourly-timelog-dialog2 .mdc-dialog__surface {
  background-color: #1e232f;
}

.hourly-timelog-dialog2 .mdc-dialog__content {
  padding: 10px;
}

.hourly-timelog-dialog2 .mdc-floating-label {
  color: white !important;
}

.hourly-timelog-dialog2 .mdc-dialog__container .mdc-dialog__surface {
  height: 100%;
  max-height: 900px !important;
  width: 100%;
  max-width: 800px !important;
}

.hourly-timelog-dialog2 select {
  color: white !important;
}

/* .hourly-timelog-dialog2 .rmwc-collapsible-list__handle {
  margin: 15px -10px 5px -10px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--mdc-theme-primary);
} */

.hourly-timelog-confirm-delete .mdc-dialog__surface {
  height: auto !important;
  width: auto !important;
  background-color: #1e232f;
  color: white;
  padding: 10px;
}

.hourly-time input {
  text-align: center;
}

.mdc-list-item__primary-text {
  text-align: left;
}

.trip-dialog .mdc-dialog__container {
  height: 80%;
  width: 90%;
}

.trip-dialog .mdc-dialog__surface {
  height: 100%;
  width: 100%;
}

.trip-dialog .mdc-dialog__content {
  padding-left: 5px;
  padding-right: 5px;
}

.trip-dialog .DayPickerInput ::-webkit-input-placeholder {
  opacity: 1;
  text-transform: capitalize;
}

/* This has probably been somewhere else too, bad to have the same rule a 100 times */
.trip-dialog .mdc-floating-label {
  color: white !important;
}

.route-delete-button {
  width: 36px;
  height: 36px;
  padding: 0px;
  margin-right: 5px;
}

.route-delete-button::before {
  background-color: transparent;
}

.border-pseudo::after, .border-pseudo-top-margin::after {
    content: '';
    margin: 0px -10px 5px -10px;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--mdc-theme-primary);
    display: block;
}

.border-pseudo-top-margin::after {
  margin-top: 5px;
}

/* Force the DayPickerInput element to capitalize stuff in Finnish, because for whatever reason non-English languages aren't capitalized */
.DayPicker-Caption > div, .DayPickerInput input {
  text-transform: capitalize;
}

.trip-route-dialog .mdc-dialog__container {
  height: 100%;
  width: 100%;
}

.trip-route-dialog .mdc-dialog__surface {
  background-color: #1e232f;
}

.employer-trip-route-dialog .mdc-dialog__surface {
  background-color: #1e232f !important;
}

.dialog-background .mdc-dialog__surface {
  background-color: #1e232f;
}

.mui-dialog-background .MuiDialog-paper, .mui-dialog-background .MuiDialogContent-root * {
  background-color: #1e232f;
  color: white;
}

.mui-dialog-background .MuiDialogContent-root {
  padding: 20px 24px;
}

.mui-dialog-background .MuiDialogContent-root * {
  /* Copied from the RMWC dialog */
  letter-spacing: 0.031em;
}

.mui-dialog-background button {
  /* Copied from the RMWC dialog */
  letter-spacing: 0.09em;
}

.mui-dialog-background .MuiDialogTitle-root > h2 > button {
  color: #c7c9cc;
}

.trip-route-dialog .mdc-dialog__container .mdc-dialog__surface {
  height: 100%;
  max-height: 900px !important;
  width: 100%;
  max-width: 800px !important;
}

.trip-route-dialog .mdc-dialog__content {
  padding: 10px;
}

.trip-route-dialog .mdc-floating-label {
  color: white !important;
}

.employer-trip-route-dialog .mdc-text-field * {
  color: white !important;
}

.employer-trip-route-dialog .mdc-dialog__actions button {
  line-height: 1;
}

.timelog-trip-route-dialog .mdc-dialog__surface {
  width: 400px;
}

.cancel-button {
  color: #c7c9cc !important;
  border: 1px solid #c7c9cc;
}

.accept-button:enabled {
  background-color: var(--mdc-theme-primary);
  color: #1e232f;
  letter-spacing: 1.25px !important;
}

.accept-button:disabled {
  background-color: var(--mdc-theme-primary);
  opacity: 0.5;
  letter-spacing: 1.25px !important;
}

.mui-button-hover-overwrite:hover {
  background-color: #ffc800cf !important;
}

/* We want all dialog buttons to have matching styles */
.mdc-dialog__button {
  width: 90px;
  text-align: center;
  border-radius: 0;
}

.route-grid {
  padding: 0px;
}

.double-dialog .mdc-dialog__container {
  height: fit-content !important;
  width: 300px !important;
}

.modal-size .mdc-dialog__container .mdc-dialog__surface {
  height: 100%;
  max-height: 900px !important;
  width: 100%;
  max-width: 800px !important;
}

.modal-size .mdc-dialog__container {
  height: 100%;
  width: 100%;
}

.small-modal-size .mdc-dialog__container .mdc-dialog__surface {
  height: 100%;
  max-height: 700px !important;
  width: 100%;
  max-width: 600px !important;
}

.small-modal-size .mdc-dialog__container {
  height: 100%;
  width: 100%;
}

.MuiDialogActions-root {
  justify-content: space-between;
  padding: 15px !important;
}

.mui-billing-table .MuiTableCell-root {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.red-helper-texts .mdc-text-field-helper-text {
  color: red !important;
}

.limited-width-dialog .mdc-dialog__container {
  max-width: 700px;
}

.employer-modal .mdc-dialog__content {
  background-color: #F6F6F6;
  padding: 0px;
}

.employer-modal .mdc-dialog__actions {
  background-color: white;
}

.employer-delete-modal .mdc-dialog__surface,
.employer-modal .mdc-dialog__surface {
  background-color: #F6F6F6;
}

.employer-delete-modal .mdc-dialog__content {
  color : #000000 !important;
}

@media (max-width: 479px) {
  .route-row {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }
  .route-address-info {
    grid-column-end: span 6;
  }
  .mobile-fit-content {
    width: fit-content;
  }
  .mobile-column {
    width: 75px !important;
  }

  .resized-mobile-kms-route-cell {
    width: 75px !important;
  }

  .allowance-column {
    width: 50% !important;
  }

  .work-order-trip-column{
    width: 32.5% !important;
  }
}

.less-left-floating-label .mdc-floating-label {
  left: 10px;
}

.mdc-dialog--open .mdc-dialog__scrim {
  background-color: rgba(0,0,0,.62) !important;
}

.trip-route-dialog input,
.trip-route-dialog textarea {
  color: white !important;
}

.hourly-timelog-dialog .mdc-dialog__container {
  height: 100%;
  width: 100%;
}

.hourly-timelog-dialog .dialog-inner-container {
  padding: 0 20px 20px 20px;
}

.hourly-timelog-dialog .dialog-form-container {
  /* margin-top: 15px; */
  background-color: var(--mdc-theme-surface);
  padding: 15px;
}

.hourly-timelog-dialog .dialog-form-container label {
  color: white !important;
}

.hourly-timelog-dialog .mdc-dialog__content {
  padding: 0;
}

.hourly-timelog-dialog .mdc-list-item {
  padding-left: 10px;
  padding-right: 10px;
}

.hourly-timelog-dialog .rmwc-collapsible-list__children-inner .mdc-list-item {
  height: fit-content;
  min-height: 48px;
  text-align: left;
}

/* .hourly-timelog-dialog .pk-time-field input {
  padding-left: 35px !important;
} */

.hourly-dialog-day-str {
  text-transform: capitalize;
  color: var(--mdc-theme-primary) !important;
  margin-top: 10px !important;
  margin-bottom: 15px;
}

.hourly-timelog-dialog .mdc-dialog__container .mdc-dialog__surface {
  height: 100%;
  max-height: 100% !important;
  width: 100%;
  max-width: 100% !important;
  background-color: var(--mdc-theme-secondary);
}

.big-time-field input {
  font-size: 25px;
  background-color: #0a130d !important;
  color: white !important;
}

.hourly-dialog-table-content .rmwc-data-table__cell {
  border: 0;
}

.wide-collapsible .rmwc-collapsible-list__handle div {
  float: right;
  height: 35px;
  border: 1px solid white;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 10px;
}

.wide-collapsible .rmwc-collapsible-list__children {
  width: 100%;
}

.wide-collapsible .rmwc-collapsible-list__children-inner div {
  border-bottom: 1px solid gray;
  white-space: normal;
  text-align: left;
  height: fit-content;
  padding: 15px;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.absolute-collapsible .rmwc-collapsible-list__handle {
  padding-left: 15px;
}
.absolute-collapsible .rmwc-collapsible-list__children {
  position: absolute;
  z-index: 2;
}

/* .lighterCell {
  background-color: #1e232f;
}

.lighterCell div input {
  background-color: #1e232f;
} */

.pk-time-field, .pk-number-field {
  border-radius: 0;
}

/* .timelog-fake-table .pk-number-field-warn > input {
  color: #e29b20 !important;
} */

.timelog-fake-table .pk-number-field-warn {
  background-color: #e29b20 !important;
}

.timelog-fake-table .pk-number-field-warn > input {
  color: white !important;
  font-weight: 700;
}

.rmwc-data-table__body .rmwc-data-table__row--hover:not(.rmwc-data-table__row--selected):not(.rmwc-data-table__row--activated) .rmwc-data-table__cell:before,
.rmwc-data-table__body .rmwc-data-table__row:hover:not(.rmwc-data-table__row--selected):not(.rmwc-data-table__row--activated) .rmwc-data-table__cell:before {
  opacity: 0 !important;
}

.border-on-dark .mdc-checkbox__native-control:enabled/*:not(:checked)*/:not(:indeterminate)~.mdc-checkbox__background {
  border-color: white;
}

/* Registration */
.steps-content .mdc-text-field:not(.mdc-text-field--disabled)+.mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: white !important;
}

/* Employer work order form */
.work-order__list .mdc-text-field:not(.mdc-text-field--disabled)+.mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: red !important;
}

.autocompletable-address .mdc-text-field-helper-text {
  transition: unset;
  opacity: unset;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: initial;
}

.DayPickerInput-Overlay-Right {
  /* By default .DayPickerInput-Overlay has left: 0 but we need to change it to right: 0 sometimes to prevent the calendar overlay from going outside the screen */
  left: unset;
  right: 0;
}

.DayPickerInput-Overlay-Top {
  /* By default .DayPickerInput-Overlay has left: 0 but we need to change it to right: 0 sometimes to prevent the calendar overlay from going outside the screen */
  bottom: 65px;
}

.mdc-text-field--disabled .mdc-text-field__input {
  background-color: #1E232F!important;
  border: none;
}

.mdc-text-field--textarea.mdc-text-field--disabled,
.mdc-text-field--disabled .mdc-text-field__input {
  background-color: var(--mdc-theme-secondary, #018786) !important;
}

.profile__details .mdc-list-item,
.profile__details h4,
.profile__details .mdc-layout-grid {
  padding-left: 0;
  padding-right: 0;
}

.no-resize > textarea {
  resize: none;
}

.hourly-timelog-textarea > textarea {
  height: 56px;
  border-radius: 0 !important;
}

.hourly-timelog-textarea #placeholder {
  padding-top: 10px;
}

.textarea-border > textarea {
  border-radius: 5px 5px 0px 0px;
}

.hourly-timelog-hour-field > input {
  font-size: 22px;
  text-align: center;
}

.pk-switch .mdc-switch:not(.mdc-switch--checked) .mdc-switch__track {
  background-color: white;
}

.pk-switch-reverse {
  flex-flow: row-reverse;
  gap: 10px;
  margin: 10px 0;
}

.pk-select.mdc-select:not(.mdc-select--disabled) {

  height: 2.9rem;
  /* margin: 0; */
}

.pk-select.mdc-select:not(.mdc-select--disabled) i {
  display: none;
}

.pk-select.mdc-select:not(.mdc-select--disabled) label {
  color: white !important;
}

.pk-select.mdc-select:not(.mdc-select--disabled) select {
  background-color: #2F3440;
  color: white;
  height: 2.9rem;
  text-align: -webkit-center;
  text-align-last:center;
}

.profile-fileinput-95-view-width {
  height: 30vh !important;
  width: 95vw;
  margin: auto;
}

.profile-input-95-view-width {
  height: auto !important;
  width: 95vw;
  margin: auto;
  border-bottom: 1px solid #49515C !important;
  /* Need to overwrite the background-color here because no idea where the whitesmoke/#f5f5f5 comes from  */
  background-color: unset !important;
}

.input-with-delete-button-employer-wrapper {
  width: 100%;
  display: flex;
  margin: 0 9px;
}

.input-with-delete-button-employer {
  /* Input */
  flex-grow: 1;
}

.input-with-delete-button-employer-wrapper > i {
  /* Icon */
  margin-left: 15px !important;
}

.left-aligned-helptext .mdc-text-field-helper-line {
  width: 100%;
}

.profile-input-75-view-width {
  height: auto !important;
  width: 75vw;
  margin: auto;
  border-bottom: 1px solid #49515C !important;
  /* Need to overwrite the background-color here because no idea where the whitesmoke/#f5f5f5 comes from  */
  background-color: unset !important;
}

.profile-input-50-view-width {
  height: auto !important;
  width: 50vw;
  margin: auto;
  border-bottom: 1px solid #49515C !important;
  /* Need to overwrite the background-color here because no idea where the whitesmoke/#f5f5f5 comes from  */
  background-color: unset !important;
}

/* Only use this class if 2 elements needs to be in one container side by side. */
.profile-input-45-view-width{
  height: auto !important;
  width: 45vw;
  border-bottom: 1px solid #49515C !important;
  /* Need to overwrite the background-color here because no idea where the whitesmoke/#f5f5f5 comes from  */
  background-color: unset !important;
}

/* Helper styling for class above. */
.profile-input-45-view-width:first-child{
  margin-right: 2.5%;
}

@media only screen and (max-width: 880px) {
  /* Helper styling for class above. */
  .profile-input-45-view-width{
    width: 45vw;
    margin-left: 2.5%;
  }
}

.rmwc-data-table__body > tr > td,
.rmwc-data-table__head > tr > th,
.rmwc-data-table__body > tr > td > div > input {
  background: #2F3440 !important;
  border: none;
}

.hourly-timelog-dialog .rmwc-data-table__body > tr > td,
.hourly-timelog-dialog .rmwc-data-table__head > tr > th,
.hourly-timelog-dialog .rmwc-data-table__body > tr > td > div > input{
  background: var(--mdc-theme-surface, white) !important;
  border: none;
}

textarea::placeholder, input::placeholder {
  color: white !important;
}

a,
a:visited {
  color: var(--mdc-theme-primary, #6200ee);
  text-decoration: none;
}

.headline {
  border-bottom: 1px solid #E4B618;
}

/* For styling a SimpleListItem (CollapsibleList handle) to look identical to other headlines in EmployerWorkOrderDetail */
.fake-headline-list-item {
  padding-left: 25px;
  height: 38px;
}

.fake-headline-list-item i:first-of-type {
  /* A hack imitating <Icon>'s sizing option but with SimpleListItem's "graphic" prop */
  color:rgba(0, 0, 0, 0.38);
}

.fake-headline-list-item .content {
  padding-left: 25px;
}

.no-bg {
  background: none !important;
}

.employer-work-hour-card {
  /* We want to overwrite the .mdc-card's display: flex because it breaks stuff on iOS safari (cards get screwy and overlap) */
  display: block !important;
  width: 90%;
  min-width: 335px;
  /* margin: 10px 0; */
  max-width: 335px;
  font-size: 17.5px;
  margin: 10px;
  /* Needed for desktop CSS's flex-wrap: wrap to look good */
  height: fit-content;
  box-shadow: 0px 2px 5px rgba(151,146,146, 0.5);
}

.employer-work-hour-card-title {
  display: flex;
  justify-content: space-between;
  /* background-color: var(--mdc-theme-surface); */
  background-color: #2F3440;
  color: white;
  padding: 10px;
  font-size: 14px;
}

.employer-work-order-trip-card-header {
  /* margin-bottom: 5px; */
}

.employer-work-hour-card-title-inner-wrapper {
  /* padding-bottom: 5px; */
  /* background-color: var(--mdc-theme-surface); */
  background-color: #2F3440;
  font-size: 14px;
}

.employer-work-hour-card-title-inner {
  display: flex;
  color: var(--mdc-theme-primary);
  padding: 0 10px 5px 10px;
  font-size: 14px;
}

.employer-work-order-trip-card-title-inner {
  display: flex;
  color: var(--mdc-theme-primary);
  font-size: 14px;
}

.employer-work-order-trip-card-title-inner-no-clr {
  margin-top: 5px;
  display: flex;
  font-size: 14px;
}

.employer-work-hour-card-title-inner-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
}

.employer-work-hour-card-date {
  text-transform: uppercase;
}

.employer-work-hour-card-description {
  max-width: 200px;
  word-break: break-word;
}

.employer-work-hour-card .mdc-floating-label--float-above:not(.employer-trip-route-dialog .mdc-floating-label--float-above) {
  color: rgba(0,0,0,.6) !important;
}

.employer-work-hour-card .old-value {
  font-size: 13px;
  text-decoration: line-through;
  color: darkred;
  margin-left: 2px;
}

.employer-acceptance-table-old-value {
  font-size: 12px;
  text-decoration: line-through;
  color: darkred;
  position: absolute;
  /* left: 0; */
}

.work-hour-old-value {
  font-size: 14px;
  text-decoration: line-through;
  color: #99BDE2;
  text-align: center;
}

.employer-work-hour-card .stacked-values {
  display: flex;
  flex-direction: column;
}

.employer-work-hour-card .stacked-values:nth-child(1) {
  text-align: end;
}

/* .employer-work-hour-card .mdc-text-field__icon {
  color: var(--mdc-theme-primary) !important;
} */

.employer-send-sms-checkbox {
  display: flex;
  justify-content: center;
}

.employer-send-sms-checkbox label {
  /* color: var(--mdc-theme-primary); */
  color: black;
  font-style: italic;
  font-weight: 550;
}

.employer-accept-button {
  margin: 0;
  width: 125px;
  /* The green is from buttonColors */
  /* background-color: #6ABB6A !important; */
  /* We would use --mdc-theme-primary here with RMWC components but it doesn't work with material-ui components (see Theme.jsx app wrapper that defines theme colors) */
  /* background-color: var(--mdc-theme-primary) !important; */
  background-color: #FFC900 !important;
  border-radius: 0 !important;
  color: black !important;
  box-shadow: 0px 1px 3px rgba(0,0,0, 0.2), 0px 2px 1px rgba(0,0,0, 0.12), 0px 1px 1px rgba(0,0,0, 0.14);
  letter-spacing: 1.25px !important;
}

.employer-timelog-wrapper .employer-accept-button:disabled, .employer-timelog-wrapper .employer-reject-button:disabled {
  opacity: 0.5;
}

.employer-cancel-button {
  margin: 0;
  width: 125px;
  /* The green is from buttonColors */
  background-color: var(--mdc-theme-primary) !important;
  border-radius: 0;
  color: black !important;
  box-shadow: 0px 1px 3px rgba(0,0,0, 0.2), 0px 2px 1px rgba(0,0,0, 0.12), 0px 1px 1px rgba(0,0,0, 0.14);
}

/* Meant to be used on white/light backgrounds */
.employer-reject-button {
  color: gray !important;
  border: 1px solid gray !important;
  border-radius: 2px !important;
  width: 125px;
  letter-spacing: 1.25px !important;
}

/* Meant to be used on dark backgrounds (mui elements) */
.employee-reject-button {
  color: #c7c9cc !important;
  border: 1px solid #c7c9cc;
  border-radius: 0px;
}

/* Meant to be used on dark backgrounds (mui elements) */
.employee-reject-button {
  /* RMWC theme colors don't work with material-ui components (see Theme.jsx app wrapper that defines theme colors) */
  color: #c7c9cc !important;
  border: 1px solid #c7c9cc !important;
  border-radius: 0px !important;
  min-width: 80px !important;
}

/* Meant to be used on dark backgrounds (mui elements) */
.employee-accept-button {
  /* RMWC theme colors don't work with material-ui components (see Theme.jsx app wrapper that defines theme colors) */
  background-color: #FFC900 !important;
  border-radius: 0 !important;
  color: black !important;
  /* box-shadow: 0px 1px 3px rgba(0,0,0, 0.2), 0px 2px 1px rgba(0,0,0, 0.12), 0px 1px 1px rgba(0,0,0, 0.14); */
  min-width: 80px !important;
}

/* Meant to be used on dark backgrounds (mui elements) */
.employee-accept-button-outlined {
  /* RMWC theme colors don't work with material-ui components (see Theme.jsx app wrapper that defines theme colors) */
  border: 1px solid #FFC900 !important;
  border-radius: 0 !important;
  color: #FFC900 !important;
  /* box-shadow: 0px 1px 3px rgba(0,0,0, 0.2), 0px 2px 1px rgba(0,0,0, 0.12), 0px 1px 1px rgba(0,0,0, 0.14); */
  min-width: 80px !important;
}

.employer-work-order-text-gray-sm {
  color: #000000;
  font-size: 12px;
  opacity: 0.6;
}

.employer-work-order-sticky-bottom {
  width: 100%;
  background-color: white;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  text-align: center;
  /* z-index: 1;
  background-color: var(--mdc-theme-secondary); */
 }

 .employer-wo-filter .mdc-select__icon {
  color: #000000 !important;
 }

 .employer-work-order .mdc-text-field-helper-text {
  color: red !important;
  opacity: 1;
 }

 .employer-work-order .mdc-select-helper-text {
  color: red !important;
  opacity: 1;
 }

 .work-period-form-daypicker {
  color: 'black';
 }

 .employer-bottom-buttons {
  margin: 0px -16px;
  padding: 16px;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  background-color: white;
  z-index: 1;
 }

 .employer-icon .rmwc-icon {
   color:black !important;
 }

 /* .employer-work-order .mdc-text-field-helper-line {
  padding: 0px;
 } */

 .employer-checkbox {
  margin-left: 0;
  margin-right: 0;
  height: 44px;
  margin: 0;
  min-width: 40px;
  padding: 0;
  color: black;
 }

 .employer-checkbox .mdc-checkbox__background {
  border-color: black !important;
 }

 .employer-checkbox .mdc-checkbox--selected .mdc-checkbox__background,
 .employer-checkbox .mdc-checkbox--selected::before,
 .employer-checkbox .mdc-checkbox--selected::after
 /* .employer-checkbox [class*="mdc-checkbox--anim-"] */
 {
  background-color: black !important
 }

 .availability-list-item::after, .availability-list-item::before {
   content: none !important;
 }

.allowance-list-item {
  height: fit-content;
}

.allowance-list-item i {
  color: white;
}

.project-rejection-icon {
  margin-left: 5px;
  font-size: 16px;
  color: red;
  /* height: 100%; */
  vertical-align: middle;
  margin-top: 0px;
  margin-bottom: 0px;
}

#refresh {
  animation-duration: 0.6s;
  animation-name: spin;
  animation-iteration-count: infinite;
}

.add-hourly-row-btn {
  margin: 1rem 0rem;
  color: var(--mdc-theme-primary);
  border: 1px solid var(--mdc-theme-primary);
  border-radius: 0px;
}

.add-hourly-row-btn:disabled {
  background-color: gray !important;
  color: darkgray !important;
  opacity: 0.5;
}

/* TEMP FIX, doesn't have fixed like the even hackier .add-hourly-row-btn */
.add-hourly-row-button-2 {
  width: fit-content;
  color: var(--mdc-theme-primary);
  border: 1px solid var(--mdc-theme-primary);
  border-radius: 0px;
}

.add-hourly-row-button-2:disabled {
  background-color: gray !important;
  color: darkgray !important;
  opacity: 0.5;
}

.timelog-fake-table {
  color: white;
  width: 100%;
  overflow: scroll;
  background-color: var(--mdc-theme-secondary);
}

.sticky-left {
 position: sticky;
 position: -webkit-sticky;
 left: 0;
 z-index: 1;
 background-color: var(--mdc-theme-secondary);
}

/* Remove the black borders that appear below the inputs. Does not overwrite the yellow border that appears on focus */
.timelog-fake-table input,
.trip-route-dialog textarea {
  border-bottom: 0;
  color: white !important;
}

.employer-comment {
  display: flex;
  font-size: 0.875rem;
  margin-left: 10px;
  margin-top: 5px;
  /* color: var(--mdc-theme-primary); */
  color: red;
}

.employer-comment-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
  font-weight: bold;
}

.vertical-align-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* .vertical-align-top {
  margin-top: 17px;
} */

.vertical-align-top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 15px;
}

.align-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.timelog-row-icon .rmwc-icon {
  position: absolute;
  top: 0;
  left: -5px;
  /* background-color: var(--mdc-theme-surface);
  border-radius: 10px; */
  font-size: 20px;
  padding: 1px;
  /* On some tabs the icon gets a bit hidden without this due to variance in date column width */
  z-index: 2;
}

.timelog-row-icon-hourly {
  position: absolute;
  width: 87px;
}

.timelog-row-icon-hourly .rmwc-icon {
  position: absolute;
  right: 0;
  font-size: 20px;
  padding: 1px;
  /* On some tabs the icon gets a bit hidden without this due to variance in date column width */
  z-index: 2;
  /* Minor position adjustment so that these hourly icons match .timelog-row-icons better */
  margin-bottom: 4px;
}

.primary-text {
  color: var(--mdc-theme-primary) !important;
}

.primary-text .MuiSvgIcon-root path,
.primary-text .MuiSvgIcon-root circle {
  fill: var(--mdc-theme-primary) !important;
}

.filter-dropdown {
  width: 100%;
  color: gray !important;
  background-color: whitesmoke;
  border-bottom: darkgray 2px solid;
}

.filter-dropdown .rmwc-icon {
  color: gray;
}

/* Checkbox labels */
.filter-dropdown label {
  color: gray !important;
}

/* Used in ProductDialog.js at the time of writing */
.white-checkbox .mdc-checkbox__background {
  border-color: white !important;
}

.green-text {
  color: #5DB85F;
}

/* .timelog-fake-table .pk-time-field {
  height: 100% !important;
} */

/* .timelog-fake-table .pk-multiline-header {
  white-space: pre;
} */

.autocomplete-location-menu .mdc-list-item {
  height: fit-content;
  min-height: 50px;
}

.location-suggestion-wrapper {
  display: flex;
  flex-direction: column;
  text-align: start;
  height: fit-content;
}

.table-wrapper {
  margin: 20px;
}

/* custom collapsible built for data-table START */

.custom-collapsible {
  overflow: hidden;
  max-height: 0;
  transition: opacity 0.3s; /* , max-height 0.3s; */
  opacity: 0;
  /* visibility: collapse; */
}

.custom-collapsible > td, .custom-collapsible .rmwc-data-table {
  padding: 0 !important;
  height: 0;
  max-height: 0;
  /* border-collapse: collapse; */
  border: none;
  line-height: 0;
  /* transition: height 0.3s, max-height 0.3s; */
}

.custom-collapsible-open {
  max-height: none;
  opacity: 1;
  /* visibility: initial; */
}

.custom-collapsible-open > td, .custom-collapsible-open .rmwc-data-table {
  height: fit-content !important;
  max-height: fit-content !important;
  /* border-collapse: initial; */
  /* border: initial; */
  line-height: initial;
}

.close-call-report-dialog .mdc-dialog__title::before {
  height: 0;
}

/* custom collapsible built for data-table END */

.employer-dialog-input {
  background-color: white !important;
  width: 100%;
  margin: 0.3em 0px;
}

@-moz-keyframes spin {
  from { -moz-transform: rotate(0deg); }
  to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

#shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
    background-color: #b00020;
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.mdc-select__dropdown-icon {
  filter: invert(94%) sepia(50%) saturate(2%) hue-rotate(172deg) brightness(112%) contrast(101%) !important;
}

.MuiTableRow-root > * {
  z-index: 2 !important;
}

.MuiTableRow-root > .MuiTableCell-body {
  z-index: 1 !important;
}

.table-selection-button {
  color: black !important;
  letter-spacing: 1.25px !important;
}
.table-selection-button:hover, .table-selection-button:focus, .table-selection-button:active {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.selected-table-selection-button, .selected-table-selection-button:active, .selected-table-selection-button:focus {
  background-color: rgba(0, 0, 0, 0.2) !important;
  color: black !important;
  letter-spacing: 1.25px !important;
}

/* This is a stupid fix for Safari where the liveAnnounce's position:absolute stretches the viewport beyond the HTML when scrolling down */
.MuiPaper-root [class^="MUIDataTable-liveAnnounce-"] {
  display: none;
}

/* dim the background when mui datatable menus are opened */
div.MuiPopover-root {
  background-color: rgba(0, 0, 0, 0.62);
}

.view-heading {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  padding: 20px;
  width: 100%;
}

/* A fix for iOS safari being able to scroll the background components and break dialogs */
/* div#root.pro-boot2[aria-hidden="true"] {
  position: fixed;
}

.mdc-dialog-scroll-lock {
  position: fixed;
} */

/* MATERIAL UI COMPONENT STYLE OVERWRITES */
/* .mui-label-light label.Mui-focused {
  color: rgba(0, 0, 0, 0.54) !important;
}

.mui-textfield-light .MuiInputLabel-root {
  left: 16px;
}
 */

/* Mui component on light background */
/* .mui-textfield-light {
  border-radius: 4px 4px 0px 0px;
}

.mui-textfield-light .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.87);
}

.mui-textfield-light:hover {
  border-bottom: 0px solid;
}

.mui-textfield-light .Mui-focused {
  border-color: #E4B618;
  border-width: 2px;
}

.mui-textfield-light .MuiInput-root::after {
  border-color: #E4B618;
  border-width: 2px;
} */

/* Mui component on dark backkground */
.mui-label-dark label.MuiFormLabel-root{
  color: white;
}

.mui-label-dark label.Mui-focused {
  color: white;
}

.mui-select-dark {
  border-radius: 4px 4px 0px 0px;
}

.mui-select-dark:hover {
  opacity: .86;
}

.mui-select-dark .MuiNativeSelect-select:focus {
  background-color: rgba(0, 0, 0, 0);
}

.mui-select-dark .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.87);
}

.mui-select-dark .MuiInput-underline:after {
  border-bottom-color: #E4B618;
  border-width: 1px;
}

.mui-select-dark .MuiNativeSelect-filled {
  text-align: center;
}

.mui-textfield-dark {
  border-radius: 4px 4px 0px 0px !important;
  margin: 8px 0px 8px 0px !important;
  z-index: 0;
}

.mui-textfield-dark:hover {
  opacity: .86;
}

.mui-textfield-dark textarea.MuiFilledInput-inputMultiline {
  color: white !important;
}

.mui-textfield-dark .Mui-focused fieldset{
  border-color: #E4B618 !important;
  border-width: 1px !important;
}

.mui-textfield-dark .MuiFilledInput-root::after {
  border-color: #E4B618 !important;
  border-width: 1px !important;
}

.mui-textfield-dark .MuiFilledInput-root {
  background-color: rgb(47, 52, 64, 0) !important;
}

.mui-textfield-dark .MuiFilledInput-inputMultiline {
  color: white !important;
}

.mui-textfield-dark .MuiFormHelperText-contained{
  color: #f44336;
}

div.mui-textfield-employee .MuiFilledInput-root {
  background-color: var(--mdc-theme-secondary) !important;
  /* border-radius: 0; */
}

div.mui-textfield-employee .MuiFilledInput-root textarea {
  color: white;
}

.mui-textfield-employee .MuiFilledInput-underline:after {
  border-bottom-color: var(--mdc-theme-primary) !important;
}

.mui-textfield-employee label.MuiFormLabel-root {
  color: white !important;
}

.responsive-dialog-title-button-only {
  align-self: flex-end;
  padding: 10px 10px 0 10px !important;
}

.responsive-dialog-stacked-action-buttons {
  flex-direction: column;
  gap: 20px;
}

.responsive-dialog-stacked-action-buttons button {
  width: 100%;
}

/* A hack to make the floating label visible in fields autofilled by Safari. Without this it is just white text on cream-colored background and barely visible */
input.mdc-theme--secondary-bg.mdc-text-field__input + label.mdc-floating-label {
  background-color: var(--mdc-theme-secondary, black) !important;
}

/* Forcing the text to be white because there's no time to figure out what is wrong with the RMWC theme */
.employer-update-comment-input label.mdc-floating-label {
  color: white !important;
}

/* We explicitly define the dialog content to be small in order to prevent stretching on browsers like Safari  */
/* .mini-dialog-content {
  max-height: 350px;
  max-width: 300px;
} */

/* We want to overwrite .modal-size height and width 100% for small dialogs to make nested dialogs work properly, e.g. on Safari */
.mini-dialog .mdc-dialog__container {
  height: initial !important;
  width: initial !important;
}

/* Three button setup with multi-line text in at least one of them, e.g. "Tallenna ja hyväksy" will wrap and take vertical space on smaller screens with the default 15px padding */
.dialog-actions-less-padding {
  padding: 15px 10px !important;
}

.justify-content-space-between {
  justify-content: space-between !important;
}

.readonly-dialog-label {
  color: black;
  font-size: 14px;
  font-weight: bold;
}

.chat-message-action-dialog .mdc-dialog__container {
  height: 250px;
  width: 285px;
}

.work-period-date-picker-wrapper > div.theme-container > div {
  width: 100%;
}

.work-period-dialog .theme-container {
  height: fit-content !important;
  width: 100%;
}

.work-period-dialog .theme-container .mdc-layout-grid__cell {
  width: 100%;
  color: black;
}

.disabled-employer-text-field > input.mdc-text-field__input {
  background-color: white !important;
}

.disabled-employer-text-field > label.mdc-floating-label {
  color: rgba(0,0,0,.37) !important;
}

.marginless-material-dialog .MuiDialog-paper {
  margin: 0 !important;
}

.rmwc-select {
  width: 100%;
  margin-top: 20px;
  background-color: white !important;
}

.work-period-switch label {
  color: black;
}

.rmwc-grip-gap-10 > .mdc-layout-grid__inner {
  gap: 10px;
}

/* .sticky-mui-table-column-cell {
  position: sticky;
  left: 0;
  background: white;
  z-index: 101 !important;
  min-width: 120px;
} */

.global-snackbar > .MuiSnackbarContent-root {
  background-color: #171A23;
  font-size: 16px;
}



/* Custom Tooltip, overriding default styling, dont like the !important usage, any better way to change the styling? */
.MuiTooltip-tooltip  {
  background-color: #0C131D !important;
  font-size: 12px !important;
  padding: 7px !important;
}

.MuiTooltip-arrow {
  color: #0C131D !important;
}

/* A stupid hack to force a specific load order for the MuiIconButtons, for example the action buttons + pagination arrows in MuiDatatables */
/* Without this, the .MuiIconButton-root and .MuiButtonBase-root loading is unstable and sometimes they load in incorrect order, changing the button styles */
/* At the time of writing, the version packages are: material-ui/core 4.12.0, mui-datatables 3.8.0 */
/* There's a chance that this problem is solved when updating to MUI v5 and mui-datatables v4 */
.MuiIconButton-root.MuiButtonBase-root {
  /* These declarations are just copied from .MuiIconButton-root */
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
}
