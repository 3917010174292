.timelog-report-html-table {
  width: 100%;
  font-size: 14px;
  background-color: white;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
  border-spacing: 0;
  border-collapse: collapse;

  /* Scrolling for sticky columns/headers */
  display: block;
  overflow-y: auto;
  max-height: calc(100vh - 250px);
}

/* CSS for sticky table headers */
.timelog-report-html-table th {
  padding: 8px;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 1; /* Ensure headers are above other table content */
  background-color: white;
}

/* CSS for sticky first column */
.timelog-report-html-table .sticky-column {
  position: sticky;
  left: 0;
  background-color: white; /* Ensure it matches the table background */
  z-index: 2; /* Ensure the first column is above other table content */
}

.employer-timelog-wrapper tr:nth-child(even) .sticky-column {
  background-color: #F5F5F5;
}

/* Ensure the z-index for the first column header is higher */
.timelog-report-html-table th.sticky-column {
  z-index: 3;
}

.timelog-report-html-table td {
  padding: 5px 10px;
  text-align: left;
  white-space: pre;
}

.timelog-report-html-table th {
  min-height: 50px;
  border-bottom: 1px solid lightgray;
}

.only-print {
  display: none;
}

.timelog-report-html-table .work-order-name {
  line-height: 1;
  width: 150px;
  white-space: normal;
}

@media print {
  .timelog-report-html-table .custom-tooltip-icon {
    display: none;
  }

  .only-print {
    display: initial;
  }

  .no-print {
    display: none;
  }

  .timelog-report-html-table .custom-tooltip-wrapper span {
    background-color: inherit !important;
    text-shadow: none;
    padding: 0;
    color: black;
  }

  .no-print-underline {
    text-decoration-line: none !important;
  }

  .timelog-report-html-table tr {
    border-bottom: 1px lightgrey solid;
  }

  /* Revert the sticky column + scroll CSS for printing */
  .timelog-report-html-table {
    width: 100%;
    display: table;
    overflow-y: visible;
    max-height: none;
  }

  /* Revert the sticky column + scroll CSS for printing */
  .timelog-report-html-table th,
  .timelog-report-html-table .sticky-column {
    position: static;
    background-color: transparent;
    z-index: auto;
  }
}
