.heading-tab {
  font-size: 24px;
  margin: 0 10px;
  cursor: pointer;
}

.heading-tab.active {
  text-decoration: underline;
  text-decoration-color: var(--mdc-theme-primary);
}

.heading-tab.inactive {
  color: gray;
}

/* A hack to force the textarea to a certain height in its initial state, without this the height is 0 */
/* Also used in user validities (profile) */
.textarea-hack > textarea {
  min-height: 72px;
}
