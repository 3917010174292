.mdc-dialog.work-order-trip-card-dialog .mdc-dialog__surface {
  /* For some reason things break if this is 335px like the regular cards, probably inheriting styles from dialog */
  width: 350px;
  min-width: 350px;
}

.mdc-dialog.work-order-trip-card-dialog .mdc-dialog__content {
  padding: 0;
}

.mdc-dialog.work-order-trip-card-dialog .employer-work-hour-card {
  margin: 0;
}
