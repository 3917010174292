.employer-timelog-wrapper .MuiTableCell-head {
    white-space: nowrap;
}

/* .employer-timelog-wrapper .MuiToolbar-root, */
.employer-timelog-wrapper .MuiTableFooter-root,
.employer-timelog-wrapper .MuiTableHead-root th {
  background-color: #C8CACF;
}

.employer-timelog-wrapper .MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: #C8CACF;
}

.employer-timelog-wrapper tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.04);
}

.employer-timelog-wrapper td {
  border: none;
}

.employer-timelog-wrapper .MuiTableFooter-root .MuiTableCell-footer {
  border: none;
}

.employer-timelog-wrapper td .MuiCheckbox-root {
  padding-top: 0;
  padding-bottom: 0;
}

/* .employer-timelog-wrapper .MuiTableCell-head {
  z-index: initial !important;
} */

.custom-tooltip-wrapper {
  /* Need this because otherwise .custom-tooltip ignores the wrapper when it positions according to top/bottom for whatever reason */
  position: sticky;
  cursor: help;
  min-width: 150px;
}

.allowance-sums * {
  /* We don't want the texts to wrap which sometimes causes allowance names and counts to go to different lines */
  white-space: nowrap;
}

.custom-tooltip {
  background-color: black;
  color: white;
  position: absolute;
  bottom: 20px;
  /* padding: 10px; */
  /* z-index: -5; */
  z-index: 10;
  height: 0;
  padding: 0;
  overflow: hidden;
  transition: height 0.2s, padding 0.2s;
  white-space: nowrap;
}

.custom-tooltip-open {
  z-index: 5;
  height: fit-content;
  width: fit-content;
  /* max-width: 200px; */
  padding: 10px;
}

.custom-tooltip-underline {
  text-decoration: underline;
  text-decoration-color: lightgray;
}

/* .full-width-table {
  max-width: 100%;
  width: 100%;
}

.full-width-table > table {
  width: 100%;
} */

/* .light-table .rmwc-data-table__body > tr > td,
.light-table .rmwc-data-table__head > tr > th,
.light-table .rmwc-data-table__body > tr > td > div > input {
  background: white !important;
  border: none;
  padding: 10px;
}

.light-table .rmwc-data-table__head > tr > th {
  color: black;
  font-weight: bold;
} */

/* .old-value-row {
  font-size: 12px;
  text-decoration: line-through;
  color: darkred;
  margin-left: 2px;
}

.old-value-row td {
  padding: 0 16px !important;
  height: fit-content;
} */

.custom-sticky-table-cell {
  /* IMPORTANT: Needs to be accompanied by a custom "left: X" rule to position them correctly PER COLUMN */
  position: sticky;
  /* Due to stacking context problems with the custom-tooltip, z-index needs to be manually adjusted per column
    e.g. column 1: z-index 2
         column 2: z-index 1
  */
  z-index: 1;
}

.employer-timelog-table tr th:first-child, .employer-timelog-table tr td:first-child {
  position: sticky;
  z-index: 4;
  left: 0;
}

/* .sticky-table-header-1 {
  left: 48px;
  min-width: 100px;
  max-width: 100px;
}

.sticky-table-header-2 {
  left: 148px;
  min-width: 100px;
  max-width: 100px;
}

.sticky-table-header-3 {
  left: 248px;
} */

/* .fixed-tooltip {
  left: 450px !important;
  z-index: 99;
  background-color: black;
  width: fit-content;
  font-size: 14px;
  padding: 5px;
} */

.tooltip-employer-comment {
  white-space: normal;
}

.timelog-table-status-icon {
  position: absolute;
  top: -15px;
  right: -10px;
  pointer-events: none;
}

.timelog-table-status-icon > i {
  font-size: 20px;
}

.table-row-border-red > td:first-of-type {
  /* border-left: 5px solid #FF6A00; */
  /* We use box-shadow to position the border inside the cell and keep table neat */
  box-shadow: inset 5px 0 0 0 #FF6A00;
  margin-left: -5px;
}

.table-row-border-orange > td:first-of-type {
  /* border-left: 5px solid #FF9E00; */
  /* We use box-shadow to position the border inside the cell and keep table neat */
  box-shadow: inset 5px 0 0 0 #FF9E00;
  margin-left: -5px;
}

.work-hour-status-pending {
  /* Primary color */
  background-color: #FFC900;
  color: black;
  /* color: white; */
  /* text-shadow: 1px 1px 1px grey; */
  padding: 2px;
  white-space: nowrap;
}

.work-hour-status-pending + div > i.rmwc-icon {
  background-color: var(--mdc-theme-primary);
  color: white;
  font-size: 20px;
  border-radius: 100%;
  width: 20px;
  text-shadow: 1px 1px 1px grey;
}

.work-hour-status-accepted {
  background-color: #6ABB6A;
  color: white;
  text-shadow: 1px 1px 1px grey;
  padding: 2px;
  white-space: nowrap;
}

.work-hour-status-accepted + div > i.rmwc-icon {
  background-color: #6ABB6A;
  color: white;
  font-size: 20px;
  border-radius: 100%;
  width: 20px;
  text-shadow: 1px 1px 1px grey;
}

.work-hour-status-rejected {
  background-color: #FD6900;
  color: white;
  text-shadow: 1px 1px 1px grey;
  padding: 2px;
  white-space: nowrap;
}

.work-hour-status-rejected + div > i.rmwc-icon {
  background-color: #FD6900;
  color: white;
  font-size: 20px;
  border-radius: 100%;
  width: 20px;
  text-shadow: 1px 1px 1px grey;
}

.MuiTableRow-root.Mui-selected {
  background-color: rgba(0, 0, 0, 0.17) !important; /* Darker background color for selected rows */
  box-shadow: inset 4px 0 0 0 #3f51b5 !important; /* Add a left border as a shadow so it doesnt create usesless extra space */
}