.attachment-dialog-title {
  white-space: break-spaces;
  text-overflow: ellipsis;
  overflow: hidden;
}

.attachment-accordion {
  margin: 10px 0;
  border-radius: 0 !important;
}

.attachment-handle-wrapper {
  display: 'flex';
  flex-direction: 'column';
}

.attachment-timestamp {
  text-transform: capitalize;
  color: gray;
  font-size: 14px !important;
}

.image-overlay-button-wrapper {
  width: 100%;
  text-align: end;
  position: absolute;
  z-index: 1;
  top: 35px;
  right: 5px;
}
