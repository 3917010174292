.rmwc-radio-button-wrapper {
  display: flex;
  flex-direction: column;
}

.rmwc-radio-button-wrapper .mdc-radio::before, .rmwc-radio-button-wrapper .mdc-radio__background::before {
  background-color: black !important;
}

.rmwc-radio-button-wrapper label {
  font-size: 16px;
  color: black;
}

.rmwc-radio-button-wrapper * {
  border-color: #FFC900 !important;
}
