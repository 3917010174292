.subtable-wrapper {
  padding: 0 !important;
}

.subtable-wrapper .MuiPaper-root {
  box-shadow: none;
}

.billing-report-header {
  font-family: 'Roboto';
  padding-left: 20px !important;
  width: 100%;
}

.billing-report-header .header-label {
  text-transform: uppercase;
  font-size: 12px;
}

.billing-report-header .header-value {
  font-size: 22px;
  font-weight: 500;
}

.report-table .MuiTableCell-head {
  /* width: 100px; */
  font-size: 14px;
  line-height: 20px;
}

.report-table .MuiTableCell-paddingCheckbox {
  width: 32px;
}

.mobile-billing-table .MuiTableCell-footer, .mobile-billing-table .MuiTableCell-footer .MuiTablePagination-toolbar {
  padding: 0;
}

.billing-report-table {
  margin: 20px;
}

.billing-report-table .MuiTableCell-root {
  padding: 6px 24px 6px 16px !important;
  /* white-space: nowrap; */
  line-height: 1.1;
  /* vertical-align: top; */
}

.billing-report-table .MuiTableCell-head {
  white-space: nowrap;
}

.billing-report-table .MuiTableCell-body {
  white-space: pre-wrap;
}

.pdf-header {
  width: 100%;
  font-size: 10px;
  font-family: 'Roboto';
  /* Hide the header when not printing */
  display: none !important;
}

.pdf-header .MuiGrid-item {
  padding: 25px !important;
}

/* Report name and account/company name */
.pdf-header .MuiGrid-item:nth-child(2) {
  text-align: center;
  font-size: 14px;
}

/* Account picture (company logo) */
.pdf-header .MuiGrid-item:nth-child(3) {
  text-align: end;
}

.pdf-header img {
  width: 100px;
  filter: grayscale(1);
}

.bill-status {
  padding: 4px;
  width: 100%;
  max-width: 170px;
  text-align: center;
  border: 1px solid gray !important;
  color: gray;
  cursor: pointer;
}

.status-billable {
  padding: 5px;
  background-color: #FF6A00;
  color: black;
  border: none !important;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 20%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 1px rgb(0 0 0 / 14%);
}

.status-not_billed {
  background-color: var(--mdc-theme-primary);
  color: black;
}

.billing-status-button {
  /* A hack to fix a strange button resizing problem, e.g. when dialog is open this padding is used in MuiButton-text but not by default */
  /* Without this the buttons grow larger in size when the dialog is opened */
  padding: 6px 8px !important;
}

.billing-status-button-billable {
  margin: 0;
  width: 125px;
  /* We would use mdc colors here such as --mdc-theme-primary but it doesn't work with material-ui components (see Theme.jsx app wrapper that defines theme colors) */
  background-color: #FF9E00 !important;
  border-radius: 0 !important;
  color: white !important;
  box-shadow: 0px 1px 3px rgba(0,0,0, 0.2), 0px 2px 1px rgba(0,0,0, 0.12), 0px 1px 1px rgba(0,0,0, 0.14);
}

.billing-status-button-billed {
  margin: 0;
  width: 125px;
  /* The green is from buttonColors */
  /* We would use mdc colors here such as --mdc-theme-primary but it doesn't work with material-ui components (see Theme.jsx app wrapper that defines theme colors) */
  background-color: #6ABB6A !important;
  border-radius: 0 !important;
  color: white !important;
  box-shadow: 0px 1px 3px rgba(0,0,0, 0.2), 0px 2px 1px rgba(0,0,0, 0.12), 0px 1px 1px rgba(0,0,0, 0.14);
}

.billing-status-button-not_billed {
  margin: 0;
  width: 125px;
  background-color: #FF6A00 !important;
  border-radius: 0 !important;
  color: white !important;
  box-shadow: 0px 1px 3px rgba(0,0,0, 0.2), 0px 2px 1px rgba(0,0,0, 0.12), 0px 1px 1px rgba(0,0,0, 0.14);
}

/* .employer-reject-button {
  color: gray !important;
  border: 1px solid gray !important;
  border-radius: 2px !important;
  width: 125px;
} */

/* .status-ongoing {
  background-color: #6ABB6A;
} */

/* .action-buttons button.small {
  padding-top: 0 !important;
} */

/* Only for the thead of the main table, not any sub tables */
/* thead:first-of-type */
/* .report-table > div > div > table > thead * {
  background-color: black;
  color: white;
} */

/* .report-table > div > div > table > thead * th:first-of-type svg {
  background-color: white;
  color: white;
} */

/* .report-table table, .subtable {
  table-layout: fixed;
  width: 100%;
} */

.action-buttons button.small * {
  font-size: 25px;
}

/* .report-table table {
  table-layout: fixed;
} */

.report-table th {
  box-sizing: content-box;
}

.report-table tbody tr, .report-table tbody tr td:first-of-type { /* , tr:not(.subtable tr) { */
  background-color: #cecece;
}

.report-table tbody:first-of-type tr:hover, .report-table tbody tr:hover td:first-of-type {
  background-color: #adadad;
}

.subtable tr, .subtable tr td:first-of-type { /* , tr:not(.subtable tr) { */
  background-color: white !important;
}

.subtable tr:hover, .subtable tr:hover td:first-of-type {
  background-color: whitesmoke !important;
}

.custom-footer * {
  color: black !important;
}

.subtable td {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.daypicker-input .MuiInputBase-root {
  width: 300px;
}

.daypicker-value {
  width: fit-content;
}

.daypicker-input {
  border-radius: 4px 4px 0px 0px;
}

.daypicker-input input {
  text-transform: capitalize;
  text-align: start;
}

.daypicker-input:hover {
  border-bottom: 0px solid;
}

.daypicker-input .Mui-focused {
  border-color: #E4B618;
  border-width: 2px;
}

.daypicker-input .MuiInput-root::after {
  border-color: #E4B618;
  border-width: 2px;
}

.DayPicker-Day--disabled {
  cursor: default;
  pointer-events: none;
}

.daypicker-input-value {
  display: none;
}

.DayPickerInput-Overlay {
  z-index: 3;
}

/* !important because we are overwriting mui button */
/* .download-button {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: baseline !important;
  font-size: 16px !important;
  width: 100% !important;
  margin-bottom: 10px !important;   */
  /* border: 1px solid !important;
  border-radius: 0px !important; */
  /* border-color: rgb(255, 201, 0) !important; */
  /* color: rgb(255, 201, 0) !important; */
/* } */

.billing-user-heading {
  font-size: 22px;
  font-weight: 500;
}

.billing-user-heading-wrapper {
  margin-top: 40px;
  font-family: 'Roboto';
  padding-left: 20px;
  width: 100%;
}

.print-margin {
  padding-bottom: 50px;
}

.capitalized {
  text-transform: capitalize;
}

@media print {
  .printable {
    color: black;
    width: 100%;
    height: 100%;
    background-color: #F6F6F6;
    /* width: fit-content !important; */
  }

  .billing-report-table td {
    border: none;
    box-shadow: none;
  }

  .billing-report-table tbody tr:nth-child(even) {
    background-color: whitesmoke;
  }


  .billing-report-table .MuiPaper-root {
    box-shadow: none;
  }

  .billing-report-table th {
    border-bottom: 1px black solid;
  }

  .billing-report-table th * {
    padding: 0 !important;
    margin: 0 !important;
    height: fit-content;
    line-height: 1;
  }

  .print-margin {
    margin: 10px;
  }

  .print-60-width {
    width: 60%;
  }

  .print-margin-bottom-20 {
    margin-bottom: 20px;
  }

  .page-breaker {
    margin-top: 2em;
    page-break-before: always !important;
  }

  .billing-report-header {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact; /*chrome & webkit browsers*/
    background-color: black;
    box-shadow: inset 0 0 0 1000px black;
    color: white !important;
    margin: 0 !important;
  }

  .billing-report-header * {
    color: white !important;
    font-size: 12px !important;
    margin-top: 0 !important;
  }

  .billing-user-heading {
    font-size: 12px;
    font-weight: 500;
  }

  .billing-user-heading-wrapper {
    padding-left: 0 !important;
  }

  .report-info {
    font-size: 10px;
  }

  .table-wrapper {
    margin: 0 !important;
  }

  .report-table:not(.subtable) .MuiTableCell-head,
  /* .report-table .MuiTableCell-head div, */
  .report-table:not(.subtable) td {
    font-size: 9px;
  }

  .billing-report-table td,
  .billing-report-table th * {
    font-size: 8px;
  }

  .billing-report-table .custom-tooltip-underline {
    text-decoration: none;
  }

  .report-table thead, .report-table tbody {
    width: 100% !important;
  }

  .report-table table {
    table-layout: auto !important;
  }

  .custom-footer tr:first-child td {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .subtable table:first-child th {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .printable div {
    overflow: visible !important;
  }

  .pdf-header {
    display: flex !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .report-purchaser {
    order: 1 !important;
  }


  .daypicker-input-value {
    order: 2 !important;
  }

  .report-location {
    order: 3 !important;
  }

  .report-duration {
    order: 4 !important;
  }

  .report-name {
    order: 5 !important;
  }

  /* A hack to force the header to fit on 2 rows when printing, without this it uses 4 rows */
  /* As if the print viewport is md-sized */
  .billing-report-header .MuiGrid-root.MuiGrid-grid-md-3 {
    max-width: 50% !important;
    flex-basis: 50% !important;
  }

  .billing-report-header .MuiGrid-root.MuiGrid-grid-md-6 {
    max-width: 50% !important;
    flex-basis: 50% !important;
  }

  /* .billing-report-header {
    margin: 0 !important;
  } */

  /* .printable table th, .printable table td {
    font-size: 0.5em !important;
  } */

  /* Hide in print */
  .spacer {
    display: none !important;
  }

  .hidden-print {
    display: none !important;
  }

  /* Show in print */
  .show-print {
    display: block !important;
  }

  .custom-tooltip-underline {
    text-decoration: none;
  }

  .custom-tooltip, .MuiChip-root {
    display: none !important;
  }

  .billing-report-table {
    margin: 0;
  }

  .billing-report-table .MuiTableCell-root {
    white-space: initial;
    text-align: left;
  }

  .billing-report-table .MuiButtonBase-root {
    text-align: left;
    padding: 0 !important;
  }

  .billing-report-table .custom-tooltip-icon {
    display: none;
  }

  .billing-report-table .custom-tooltip-wrapper span {
    background-color: inherit !important;
  }

  /* Hide the sorting arrows that for some reason are printed as super tall
  /* We need to use the .MuiTableSortLabel-active because the sortAction class isn't there in production */
  /* .billing-report-table th [class^="MUIDataTableHeadCell-sortAction-"] span { */
  .billing-report-table th .MuiTableSortLabel-active {
    display: none;
  }

  .billing-report-table.timelog-report [class^="work-hour-status-"] {
    background-color: inherit;
    color: black;
    text-shadow: none;
    padding: 0;
    white-space: nowrap;
  }
}
