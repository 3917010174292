.salary-period-processing-tabs {
    width: 300px;
    align-self: start;
    margin-left: 20px;
    margin-top: 20px;
}

.salary-period-processing-tabs .MuiTab-wrapper {
    display: flex;
    flex-direction: row;
}

.salary-period-processing-tabs .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
    margin-bottom: 0;
    margin-right: 10px;
}

.salary-period-processing-tabs .MuiTab-labelIcon {
    min-height: initial;
}

.salary-period-processing-tabs .MuiTabs-scroller {
    height: fit-content;
}

.salary-period-processing-tabs .Mui-selected {
    background-color: #B6B6B6;
}

.salary-period-processing-tabs .MuiTabs-indicator {
    background-color: black !important;
}

.salary-period-processing-actions {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
}

.salary-period-processing-first-message > div.disabled-employer-text-field {
  background-color: white !important;
}

.salary-period-processing-first-message > div.disabled-employer-text-field > textarea {
  background-color: white !important;
}

/* MAKE THIS SAFER - VIEW-SPECIFIC */
/* .ul.MuiList-root {
  display: flex;
  flex-direction: column;
} */
