/* DESKTOP MODE */
@media (min-width: 800px) {
  .theme-container {
    flex-direction: row;
  }

  /* EMPLOYER-TIMELOG START */
  .employer-timelog-wrapper {
    width: 100%;
  }

  .filter-button {
    justify-content: flex-end;
  }

  .employer-card-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  /* EMPLOYER-TIMELOG END */

  /* SIDE MENU START */

  .employer-side-menu {
    width: 55px;
    transition: width 0.3s ease;
    overflow: hidden;
  }
  
  .employer-side-menu:hover {
    width: 256px;
    transition: width 0.3s ease;
  }

  .employer-side-menu .side-menu-list {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
  }

  .employer-side-menu-header {
    height: 50px;
    min-height: unset;
    padding-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .drawer-logo-container {
    display: flex;
    align-items: center;
    transition: opacity 0.3s ease, width 0.3s ease, height 0.3s ease;
  }
  
  .drawer-logo-container img {
    transition: opacity 0.3s ease, width 0.3s ease, height 0.3s ease;
  }
  
  /* Default state for large logo */
  .drawer-logo-container .large-logo {
    opacity: 0;
    width: 0;
    height: 0;
    display: none;
  }
  
  /* State when menu is locked */
  .drawer-logo-container .large-logo.locked {
    opacity: 1;
    width: 150px;
    height: 150px;
    display: block;
  }
  
  /* Large logo appears on hover when menu is not locked */
  .employer-side-menu:hover .drawer-logo-container .large-logo {
    opacity: 1;
    display: block;
    width: 150px;
    height: 150px;
  }
  
  /* Default state for small logo */
  .drawer-logo-container .small-logo {
    opacity: 1;
    width: 54px;
    height: 54px;
    display: block;
  }
  
  /* Small logo disappears on hover when menu is not locked */
  .employer-side-menu:hover .drawer-logo-container .small-logo {
    opacity: 0;
    width: 0;
    height: 0;
    display: none;
  }

  .lock-icon {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    opacity: 0;
    width: 0;
    height: 48px;
    transition: opacity 0.3s ease, width 0.3s ease;
  }
  
  /* Class when the menu is locked */
  .lock-icon.locked {
    opacity: 1;
    width: auto;
  }
  
  /* Class when the menu is not locked, uses hover to show the icon */
  .lock-icon.hoverable {
    opacity: 0;
  }
  
  .employer-side-menu:hover .lock-icon.hoverable {
    opacity: 1;
    width: 40px;
  }

  
  /* Show the header when the drawer is fully expanded */
  @media (min-width: 600px) {
    .employer-side-menu:hover .employer-side-menu-header {
      display: flex;
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }

  .employer-side-menu-header > .mdc-drawer__title::before {
    height: unset;
  }

  .employer-side-menu-content {
    border-top: none;
  }
  /* SIDE MENU END */
}

/* MOBILE MODE */
@media (max-width: 799px) {
  /* EMPLOYER-TIMELOG START */
  .filter-button {
    justify-content: center;
  }

  .employer-card-wrapper {
    display: flex;
    flex-direction: column;
  }
  /* EMPLOYER-TIMELOG END */
}
