.mdc-list-item::before, .mdc-list-item::after {
  /* Without this the width is 200% which causes the whole dialog to scroll horizontally */
  width: 100% !important;
}

.employee-work-task-dialog .availability-list-item {
  background-color: white;
}

.employee-work-task-dialog .upload-attachment-button {
  color: var(--mdc-theme-primary) !important;
 /* color: #FFC900 !important */
}

.employee-work-task-dialog .upload-attachment-button.active-button {
  color: black !important;
  background-color: var(--mdc-theme-primary) !important;
}

/* .employee-work-task-dialog .headline {
  color: white;
  padding: 0 !important;
  margin: 0 -16px;
} */

.status-buttons {
  display: flex;
  gap: 20px;
}

.status-buttons .mdc-button__label {
  white-space: nowrap;
}

.work-task-participants-dialog-checkbox .mdc-checkbox__background {
  background-color: white !important;
}

.work-task-participants-dialog-checkbox .mdc-checkbox__checkmark {
  color: black !important;
}
