/* IMPORTANT NOTE: The .custom-validities class is a required workaround since we can't assign a className to the collapsible list due to a bug in RMWC */
/* Should be cleaned away when RMWC is updated */

.custom-validities .rmwc-collapsible-list {
  background-color: var(--mdc-theme-surface);
}

.custom-validities .rmwc-collapsible-list__children-inner {
  padding-bottom: 15px;
}

.custom-validities .rmwc-collapsible-list--open {
  margin-bottom: 30px;
}

/* For whatever reason the ripple looks ugly in the custom validities, covers left side of the screen, so we just disable it (ripple = false prop doesn't work?) */
.custom-validities .mdc-list-item:before,
.custom-validities .mdc-list-item:after {
  display: none;
}

.custom-validities .rmwc-collapsible-list--open .rmwc-collapsible-list__handle {
  border-bottom: var(--mdc-theme-primary) 1px solid;
}

.custom-validities .collapsible-card-handle {
  box-shadow: none;
}

.custom-validities .collapsible-card-handle > i.rmwc-icon {
  font-size: 24px;
}

.custom-validities .collapsible-card-handle-title {
  /* Designed to match the work order elements beneath the calendar */
  line-height: 16px;
  font-size: 16px;
  font-weight: 400;
}

.collapsible-card-image-wrapper {
  max-width: 95vw;
  margin: 0 auto;
}

.custom-validities .input-with-delete-button-wrapper {
  display: flex;
  justify-content: space-between;
  /* Margin: auto and width: 95vw are taken from profile-input-95-view-width CSS class */
  margin: auto;
  width: 95vw;
}

.custom-validities .input-with-delete-button {
  margin: 0px;
  width: 85%;
}


/* A hack to force the textarea to a certain height in its initial state, without this the height is 0 */
/* Also used in locations-purchasers but with a different selector (accurate selector needed to overwrite height: auto from .mdc-text-field__input */
.textarea-hack.mdc-text-field--textarea > textarea.mdc-text-field__input {
  height: 56px;
  padding-bottom: 0;
  resize: none;
}

.validity-file-upload {
  border-radius: 0 !important;
  background-color: transparent;
}

.pdf-margin::before {
  margin-top: 0px !important;
}

.custom-validities-employer textarea,
.custom-validities-employer input,
.custom-validities-employer input.mdc-theme--secondary-bg.mdc-text-field__input + label.mdc-floating-label,
.custom-validities-employer .mdc-text-field--textarea.mdc-text-field--disabled,
.custom-validities-employer .mdc-text-field--disabled .mdc-text-field__input {
  background-color: white !important;
  color: black !important;
}

.custom-validities-employer .mdc-list-item {
  width: 100% !important;
}

.custom-validities-employer .input-with-delete-button-wrapper {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 100% !important;
}
