.mdc-dialog.timelog-card-dialog .mdc-dialog__surface {
  /* Same as the regular cards */
  width: 335px;
}

.mdc-dialog.timelog-card-dialog .mdc-dialog__content {
  padding: 0;
}

.tax-exempt-trip-expense-category-collapsible .rmwc-collapsible-list__handle {
  text-transform: capitalize;
  background-color: rgb(29, 35, 51);
  color: white;
  font-size: 14px;
}

.salary-period-collapsible .rmwc-collapsible-list__handle {
  background-color: lightgray;
  color: dimgray;
}

.salary-period-collapsible .rmwc-collapsible-list__handle > .mdc-list-item {
  padding: 5px 10px !important;
}

.salary-period-collapsible .rmwc-collapsible-list__handle .mdc-list-item > i.mdc-list-item__meta {
  color: dimgray;
}

.tax-exempt-trip-expense-category-collapsible .rmwc-collapsible-list__handle .mdc-list-item {
  padding: 10px;
}

.tax-exempt-trip-expense-category-collapsible .work-order-name {
  font-size: 14px;
  margin: 10px;
}

.allowance-list-item {
  padding: 5px 10px !important;
}

.category-collapsible-handle {
  display: flex;
  flex-direction: column;
  width: 80%;
  text-transform: none;
}

.tax-exempt-trip-expense-category-collapsible .tax-exempt-trip-expense,
.tax-exempt-trip-expense-reject-dialog .tax-exempt-trip-expense {
  display: flex;
  justify-content: space-between;
  /* margin: 10px 10px 10px 25px; */
  margin: 0 10px;
  font-size: 14px;
  text-transform: capitalize;
  cursor: pointer;
}

.tax-exempt-trip-expense-employer-comment {
  font-size: 12px;
  margin: 10px 10px 10px 25px;
}

/* .tax-exempt-trip-expense-employer-comment > .red {
  color: red;
  font-weight: 550;
} */

.trip-expense-category-collapsible-content {
  display: 'flex';
  flex-direction: column;
}

.rmwc-collapsible-list--open .dashed-border {
  border-bottom: 2px dashed gray;
}

.trip-action-button {
  color: black !important;
  background-color: #FEEAAF !important;
  width: 50%;
  border-radius: 0 !important;
  letter-spacing: 1.25px !important;
}

.trip-action-button .MuiButton-endIcon {
  margin: 0 !important;
}

.trip-action-button .MuiButton-startIcon {
  margin: 0 !important;
}

.trip-action-button-left {
  border-right: 1px solid #9d9d9d !important;
}

.trip-action-button-right {
  border-left: 1px solid #9d9d9d !important;
}

.tax-exempt-trip-expense-inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding: 5px 0px 5px 10px;
}

/* Button colors green */
.accepted {
  border-left: 5px solid #6ABB6A;
}

/* Primary yellow */
.pending {
  border-left: 5px solid #FFC900;
}

.rejected {
  /* color: red; */
  border-left: 5px solid red;
}

.rejected-text {
  color: red;
}
