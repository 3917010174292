.employer-timelog-view-dialog .MuiPaper-root {
  height: 100%;
  max-height: 900px !important;
  width: 100%;
  max-width: 800px !important;
  margin: 0;
}

.employer-timelog-view-dialog .mdc-dialog__surface {
  background-color: white !important;
}
