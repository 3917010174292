.sublist-heading {
  /* We're overriding .mdc-list-item styles here */
  height: 30px !important;
  margin-left: 10px;
  padding: 20px 0 !important;
}

.scheduler-content .event-item {
  color: black;
}

.box {
  background-color: dimgray;
  color: #fff;
  /* border-radius: 5px; */
  /* padding: 20px; */
  font-size: 150%;
  border: 1px solid gray;
}

/* .box:nth-child(even) {
  background-color: #ccc;
  color: #000;
} */

.wrapper {
  width: 90%;
  display: grid;
  /* grid-gap: 2%; */
  grid-template-columns: repeat(8, 10%);
  /* border:1px solid #000; */
  margin-bottom: 2em;
  font-size: 14px;
}

.wo_cal {
  background-color: lightblue;
  color: black;
  margin: 5px 0 5px 0;
  font-size: 14px;
  height: 50px;
  padding: 5px;
}

.wo_cal_first {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 10px;
}

.wo_cal_last {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-right: 10px;
}

.wo_cal_fake {
  margin: 5px 0 5px 0;
  font-size: 14px;
  height: 50px;
}

.not-participating {
  background-color: lightsteelblue !important;
}

.time {
  margin: 5px;
  border-radius: 5px;
  font-size: 12px;
  background-color: gray;
  color: white;
  padding-left: 5px;
}

.fake-name {
  height: 17px;
}

.wrapper2 {
  width: 90%;
  display: grid;
  /* grid-gap: 2%; */
  grid-template-columns: repeat(4, 20%);
  /* border:1px solid #000; */
  margin-bottom: 2em;
  font-size: 14px;
}

.wrapper2 .wo_cal {
  margin: 5px;
  border-radius: 5px;
}

