.chat .mdc-dialog .mdc-dialog__scrim {
  background-color: rgba(0,0,0,0.8);
}

.chat .mdc-dialog .mdc-dialog__title {
  color: var(--mdc-theme-primary, #6200ee);
}

.chat .mdc-dialog .mdc-dialog__content {
  color: white;
}

.chat .mdc-dialog__content {
  white-space: pre-wrap;
}

.chat .mdc-dialog .mdc-dialog__content.xl-buttons {
  text-align: center;
}

.chat .mdc-dialog .mdc-dialog__content.xl-buttons button {
  margin-left: 0;
}

.chat .xl-buttons .mdc-dialog__button.mdc-button {
  width: 20em;
}

.chat .mdc-dialog__button.mdc-button {
  border-radius: 0px;
  border: 1px solid rgb(233, 184, 1);
  color: rgb(233, 184, 1);
  font-size: 12px;
  width: 10em;
}

.chat .mdc-dialog__button.mdc-button:focus:before,
.chat .mdc-dialog__button.mdc-button:active:after {
  opacity: 0;
}

.chat .mdc-typography--subtitle2.message-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.sms {
  background-color: white;
  color: #1E232F;
  padding: 0 3px;
  user-select: none;
}
