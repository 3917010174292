.employer-work-task-card {
  background-color: white !important;
}

.employee-work-task-card [class^="invitations-"] {
  color: white !important;
}

.employer-work-task-card .span-button,
.employee-work-task-card .span-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.employee-work-task-card .work-task-card-icon {
  color: white;
}

.work-task-card-users-cell {
  font-size: 12px;
  text-align: right;
  justify-self: flex-end;
  display: flex;
  gap: 10px;
}

.employer-work-task-card .work-task-card-users-cell {
  font-weight: 500;
}

.employee-work-task-dialog .mdc-dialog__title {
  color: var(--mdc-theme-primary) !important;
}

/* .employee-work-task-dialog * {
  color: white;
} */
